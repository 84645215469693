<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="出围日期">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" size="small" placeholder="车牌号/车架号"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="carNo" slot-scope="scope">
          <el-button type="text" @click="$site.view_car_detail(scope.row.carId)">{{ scope.row.carNo }}</el-button>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarFenceINOUT',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '车牌号', slot: 'carNo' },
          { label: 'VIN码', prop: 'vin', overflow: true },
          { label: '型号', prop: 'carModel', overflow: true },
          { label: '出围时间', prop: 'outTime' },
          { label: '入围时间', prop: 'inTime' },
          { label: '间隔', prop: 'durningText' }
        ],
        operation: {
          width: '160',
          buttons: [{ type: 'primary', icon: 'el-icon-place', label: '查看轨迹', click: this.showTrack }]
        }
      },
      condition: {
        keyword: '',
        startDate: '',
        endDate: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/car/fenceinout/record', ['enterpriseCode', this.condition])
    },
    showTrack(data) {
      this.$site.view_car_detail(data.carId, {
        type: 'GPSTrack',
        start: data.outTime,
        end: data.inTime
      })
    }
  }
}
</script>
